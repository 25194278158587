<template>
    <div :class="`${$isMobile() ? '' : 'container-lg vh-100'}`">

        <!-- <div class="d-flex text-white justify-content-end align-items-center px-2 pt-2  h4" style="gap:10px">
            Filter Data <i class="bx bxs-filter-alt" style="color: #FFAD32;"></i>
        </div> -->

        <!-- <div v-for="item in [1,2,3]">
            <router-link to="/training/group" class="d-flex align-items-center px-2 pt-1" style="gap:10px 20px">
                <div>
                    <img src="https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png"
                        class="rounded-circle" style="height:80px;width:80px" />
                </div>
                <div class="flex-fill">
                    <div class="badge badge-primary" style="margin-bottom: .5em;">Company</div>
                    <h3 class="text-white h4 mb-0">Jane Smith Doe</h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center" style="margin: .25em 0 !important;font-weight: normal;gap:5px">
                        <i class="bx bxs-map" style="font-size: 18px;"></i> Jakarta, Indonesia    
                    </h3>
                </div>
                <div>
                    <h3 class="text-white h6 mb-0 text-right" style="">4.5 <i class="bx bxs-star" style="color:#FFAD32"></i></h3>
                </div>
            </router-link>
        </div> -->

        <h3 class="text-center text-white font-weight-bold h3 mt-3 mb-0">Mongrela Trainers</h3>
        <!-- <p class="px-2 text-white text-justify mt-1">
            be a part of our community and help us care for pets when their owners can't. Join our community of dedicated pet sitters, where your love for animals makes a real difference. By being a Mongrela Pet Sitter, you're not just caring for pets; you're enriching their lives with love and attention. Join us in providing top-notch care for our furry friends.        
        </p> -->

        <hr style="border-color: #FFAD32;" />

        <div class="text-center h1 text-white mt-5" v-if="trainer_list.length < 1">
            No Data Available
        </div>
        <div v-for="item in trainer_list">
            <router-link :to="`/training/individual/${item.user_id}`" class="d-flex align-items-center px-2 pt-1" style="gap:10px 20px">
                <div>
                    <img :src="item.owner.user_picture ? item.owner.user_picture : default_img"
                        class="rounded-circle" style="height:80px;width:80px" />
                </div>
                <div class="flex-fill">
                    <h3 class="text-white h4 mb-0 text-truncate" style="max-width: 180px;">{{item.owner.user_name}}</h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center" style="margin: .25em 0 !important;font-weight: normal;gap:5px">
                        <i class="bx bxs-map" style="font-size: 18px;"></i> {{item.owner.user_location}}    
                    </h3>
                </div>
                <div style="width:50px" >
                    <h3 class="text-white h6 mb-0 text-right" style="">{{ item.trainer_rating }} <i class="bx bxs-star" style="color:#FFAD32"></i></h3>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie';
let cookies = new Cookies()

export default {
    computed: {
        trainer_list(){
            return store.state.trainer.TRAINER_LIST
        }
    },
    methods: {

    },
    mounted(){
        store.dispatch('trainer/GetTrainerList', {
            user_id : cookies.get('token')
        })
    },
    data(){
        return {
            default_img : 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>